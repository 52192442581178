import banner from '../../assets/banner2.png'
import './Banner2.css'

function Banner2(){
    return (
        <div className="banner2">
            <img src={banner} alt="Bannière de la page d'accueil" />
        </div>
    )
}

export default Banner2